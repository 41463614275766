.App {
    text-align: center;
}

.MapBoxContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
}

.menu {
    background: #fff;
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    border-radius: 3px;
    width: 120px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    font-family: 'Open Sans', sans-serif;
}

.menu a {
    font-size: 13px;
    color: #404040;
    display: block;
    margin: 0;
    padding: 0;
    padding: 10px;
    text-decoration: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    text-align: center;
}

.menu a:last-child {
    border: none;
}

.menu a:hover {
    background-color: #f8f8f8;
    color: #404040;
}

.menu a.active {
    background-color: #3887be;
    color: #ffffff;
}

.menu a.active:hover {
    background: #3074a4;
}